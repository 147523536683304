"use client";

import type { getAllowed, getParsingGrant, isParsePremium } from "@/customClaims";
import type { LoginProvider } from "@/lib/firebase/auth";
import type { ReactNode } from "react";
import { createContext, use } from "react";
const AuthContext = createContext<Promise<User | null>>(Promise.resolve(null));
export const AuthProvider = ({
  children,
  value
}: {
  children: ReactNode;
  value: Promise<User | null>;
}) => {
  return <AuthContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="client.tsx">{children}</AuthContext.Provider>;
};
export interface Features {
  grant_parse_premium: ReturnType<typeof isParsePremium>;
}
export interface User {
  email: string | undefined;
  uid: string | undefined;
  displayName: string | undefined;
  photoURL: string | undefined;
  allowed: ReturnType<typeof getAllowed>;
  parsePremium: ReturnType<typeof isParsePremium>;
  parsingGrant: ReturnType<typeof getParsingGrant>;
  isStaff: boolean | false;
  features: Features;
  lastLoginProvider: LoginProvider | "oidc";
}
export const useAuth = (): User | null => {
  return use(use(AuthContext));
};