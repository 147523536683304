import { useFile } from "@/hooks/file";
import { client } from "@llamaindex/cloud";
interface ScreenshotViewProps {
  id: string;
  idx: number;
  fileId: string;
  pageIndex: number;
  projectId?: string;
  onViewInFile?: (nodeId: string) => void;
}
const ScreenshotView = ({
  id,
  idx,
  fileId,
  pageIndex,
  projectId
}: ScreenshotViewProps) => {
  const {
    data: {
      name
    }
  } = useFile(fileId, projectId);
  return <div id={`node-${id}`} className="grid grid-cols-[94px_auto] gap-x-2 gap-y-4 rounded-md border border-slate-200 bg-white p-4 text-sm font-normal leading-6" data-sentry-component="ScreenshotView" data-sentry-source-file="screenshot-view.tsx">
      <div className="col-span-2 flex flex-row gap-x-4">
        <div className="my-auto min-w-[85px] text-base font-medium">
          Node {idx} - Page Screenshot
        </div>
      </div>
      <div className="col-span-2">
        <img src={`${client.getConfig().baseUrl}/api/v1/files/${fileId}/page_screenshots/${pageIndex}`} alt="Page Screenshot" />
      </div>
      <div>File:</div>
      <div>{name}</div>
      <div>File ID:</div>
      <div>{fileId}</div>
      <div>Page:</div>
      <div>{pageIndex + 1}</div>
    </div>;
};
export { ScreenshotView };