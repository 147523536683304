"use client";

import { Label } from "@llamaindex/component/ui/label";
import { SidebarMenuButton, SidebarMenuItem } from "@llamaindex/component/ui/sidebar";
import { Book } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo } from "react";
export const DynamicDocumentLink = () => {
  const pathname = usePathname();
  const href = useMemo(() => {
    if (pathname.includes("/parse")) {
      return "https://docs.cloud.llamaindex.ai/category/API/parsing";
    }
    return "https://docs.cloud.llamaindex.ai";
  }, [pathname]);
  return <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-component="DynamicDocumentLink" data-sentry-source-file="dynamic-document-link.tsx">
      <SidebarMenuButton tooltip="Documentation" data-sentry-element="SidebarMenuButton" data-sentry-source-file="dynamic-document-link.tsx">
        <Link href={href} data-sentry-element="Link" data-sentry-source-file="dynamic-document-link.tsx">
          <Book className="inline-block size-4" data-sentry-element="Book" data-sentry-source-file="dynamic-document-link.tsx" />
          <Label className="ml-2 group-data-[collapsible=icon]:hidden" data-sentry-element="Label" data-sentry-source-file="dynamic-document-link.tsx">
            Documentation
          </Label>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>;
};