"use client";
import type { UserOrganizationDelete } from "@llamaindex/cloud/api";
import {
  addUserToProjectApiV1OrganizationsOrganizationIdUsersUserIdProjectsPut,
  addUsersToOrganizationApiV1OrganizationsOrganizationIdUsersPut,
  assignRoleToUserInOrganizationApiV1OrganizationsOrganizationIdUsersRolesPut,
  batchRemoveUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersRemovePut,
  createOrganizationApiV1OrganizationsPost,
  deleteOrganizationApiV1OrganizationsOrganizationIdDelete,
  getOrganizationApiV1OrganizationsOrganizationIdGet,
  listOrganizationUsersApiV1OrganizationsOrganizationIdUsersGet,
  listOrganizationsApiV1OrganizationsGet,
  listProjectsByUserApiV1OrganizationsOrganizationIdUsersUserIdProjectsGet,
  listRolesApiV1OrganizationsOrganizationIdRolesGet,
  removeUserFromProjectApiV1OrganizationsOrganizationIdUsersUserIdProjectsProjectIdDelete,
  removeUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersMemberUserIdDelete,
  updateOrganizationApiV1OrganizationsOrganizationIdPut,
} from "@llamaindex/cloud/api";
import type { QueryClient } from "@tanstack/react-query";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useRouter } from "next/navigation";
import { startTransition } from "react";

export const ORGANIZATION_KEY = "organization";

export async function refreshOrganizations(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [ORGANIZATION_KEY, "all"],
  });
}

export function useOrganizations() {
  return useSuspenseQuery({
    queryKey: [ORGANIZATION_KEY, "all"],
    queryFn: async () => {
      const { data } = await listOrganizationsApiV1OrganizationsGet({
        throwOnError: true,
      });
      return data;
    },
  });
}

export function useOrganization(organizationId: string) {
  return useSuspenseQuery({
    queryKey: [ORGANIZATION_KEY, organizationId, "info"],
    queryFn: async () => {
      const response = await getOrganizationApiV1OrganizationsOrganizationIdGet(
        {
          path: {
            organization_id: organizationId,
          },
          throwOnError: true,
        },
      );
      return response.data;
    },
  });
}

export function useNewOrganization() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const { data } = await createOrganizationApiV1OrganizationsPost({
        body: {
          name,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshOrganizations(queryClient);
    },
  });
}

export function useUpdateOrganizationName() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      name,
    }: {
      organizationId: string;
      name: string;
    }) => {
      const { data } =
        await updateOrganizationApiV1OrganizationsOrganizationIdPut({
          path: {
            organization_id: organizationId,
          },
          body: {
            name,
          },
          throwOnError: true,
        });
      return data;
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, organizationId, "info"],
      });
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, "all"],
      });
    },
  });
}

export function useDeleteOrganization() {
  const queryClient = useQueryClient();
  const { data: organizations } = useOrganizations();
  const { replace } = useRouter();
  return useMutation({
    mutationFn: async ({ organizationId }: { organizationId: string }) => {
      if (organizations.length === 1) {
        throw new Error("Cannot delete the last organization");
      }
      return deleteOrganizationApiV1OrganizationsOrganizationIdDelete({
        path: {
          organization_id: organizationId,
        },
        throwOnError: true,
      });
    },
    onSuccess: async (_, { organizationId }) => {
      await refreshOrganizations(queryClient);
      const targetOrganizationId = organizations.find(
        (organization) => organization.id !== organizationId,
      )?.id;
      if (targetOrganizationId) {
        startTransition(() => {
          replace(`/organization/${targetOrganizationId}/dashboard`);
        });
      }
    },
  });
}

export function useOrganizationMembers({
  organizationId,
}: {
  organizationId: string;
}) {
  return useSuspenseQuery({
    queryKey: [ORGANIZATION_KEY, organizationId, "members"],
    queryFn: async () => {
      const { data } =
        await listOrganizationUsersApiV1OrganizationsOrganizationIdUsersGet({
          path: {
            organization_id: organizationId,
          },
          throwOnError: true,
        });
      return data;
    },
  });
}

export function useAddMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      email,
      projectIds,
      roleId,
    }: {
      organizationId: string;
      email: string;
      projectIds: string[];
      roleId: string;
    }) => {
      const { data } =
        await addUsersToOrganizationApiV1OrganizationsOrganizationIdUsersPut({
          path: {
            organization_id: organizationId,
          },
          body: [{ email, project_ids: projectIds, role_id: roleId }],
          throwOnError: true,
        });
      return data;
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, organizationId],
      });
    },
  });
}

export function useDeleteMember() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      memberUserId,
    }: {
      organizationId: string;
      memberUserId: string;
    }) => {
      const { data } =
        await removeUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersMemberUserIdDelete(
          {
            path: {
              organization_id: organizationId,
              member_user_id: memberUserId,
            },
            throwOnError: true,
          },
        );
      return data;
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, organizationId],
      });
    },
  });
}

export function useDeleteMemberBatch() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      userOrganizationDeletes,
    }: {
      organizationId: string;
      userOrganizationDeletes: UserOrganizationDelete[];
    }) => {
      const { data } =
        await batchRemoveUsersFromOrganizationApiV1OrganizationsOrganizationIdUsersRemovePut(
          {
            path: {
              organization_id: organizationId,
            },
            body: userOrganizationDeletes,
            throwOnError: true,
          },
        );
      return data;
    },
    onSuccess: async (_, { organizationId }) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, organizationId],
      });
    },
  });
}

const ROLES_KEY = "roles";

export function useListRoles({
  organizationId,
  enabled = true,
}: {
  organizationId: string;
  enabled?: boolean;
}) {
  return useSuspenseQuery({
    queryKey: [ROLES_KEY, organizationId, { enabled }],
    queryFn: async () => {
      if (!enabled || !organizationId) {
        return [];
      }
      const { data } = await listRolesApiV1OrganizationsOrganizationIdRolesGet({
        path: {
          organization_id: organizationId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

export function useAssignUserRole() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      userId,
      roleId,
    }: {
      organizationId: string;
      userId: string;
      roleId: string;
    }) => {
      const { data } =
        await assignRoleToUserInOrganizationApiV1OrganizationsOrganizationIdUsersRolesPut(
          {
            path: {
              organization_id: organizationId,
            },
            body: {
              user_id: userId,
              organization_id: organizationId,
              role_id: roleId,
            },
            throwOnError: true,
          },
        );
      return data;
    },
    onSettled: async (_, __, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_KEY, variables.organizationId],
      });
    },
  });
}

const USER_PROJECTS_KEY = "userProjects";

export function useListUserProjectsByOrganizationId({
  userId,
  organizationId,
  enabled = true,
}: {
  userId: string;
  organizationId: string;
  enabled?: boolean;
}) {
  return useSuspenseQuery({
    queryKey: [USER_PROJECTS_KEY, userId, organizationId, { enabled }],
    queryFn: async () => {
      if (!enabled || !userId || !organizationId) {
        return [];
      }

      const { data } =
        await listProjectsByUserApiV1OrganizationsOrganizationIdUsersUserIdProjectsGet(
          {
            path: {
              organization_id: organizationId,
              user_id: userId,
            },
            throwOnError: true,
          },
        );
      return data;
    },
  });
}

export function useAddUserToProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      userId,
      projectId,
    }: {
      organizationId: string;
      userId: string;
      projectId: string;
    }) => {
      const { data } =
        await addUserToProjectApiV1OrganizationsOrganizationIdUsersUserIdProjectsPut(
          {
            path: {
              organization_id: organizationId,
              user_id: userId,
            },
            query: {
              project_id: projectId,
            },
            throwOnError: true,
          },
        );
      return data;
    },
    onSettled: async (_, __, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          USER_PROJECTS_KEY,
          variables.userId,
          variables.organizationId,
        ],
      });
    },
  });
}

export function useRemoveUserFromProject() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      organizationId,
      userId,
      projectId,
    }: {
      organizationId: string;
      userId: string;
      projectId: string;
    }) => {
      const { data } =
        await removeUserFromProjectApiV1OrganizationsOrganizationIdUsersUserIdProjectsProjectIdDelete(
          {
            path: {
              organization_id: organizationId,
              project_id: projectId,
              user_id: userId,
            },
            throwOnError: true,
          },
        );
      return data;
    },
    onSettled: async (_, __, variables) => {
      await queryClient.invalidateQueries({
        queryKey: [
          USER_PROJECTS_KEY,
          variables.userId,
          variables.organizationId,
        ],
      });
    },
  });
}
