import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/frontend/node_modules/.pnpm/@vercel+speed-insights@1.1.0_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+test@_cdb37hdn4givi4abdwwexl5h4u/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/driver.js@1.3.1/node_modules/driver.js/dist/driver.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/filepond@4.31.4/node_modules/filepond/dist/filepond.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/frontend/node_modules/.pnpm/next-view-transitions@0.3.4_next@15.2.0-canary.17_@opentelemetry+api@1.9.0_@playwright+test@1_7fv3n6cocizb7kmnqvkn2tzfi4/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/.pnpm/next@15.2.0-canary.17_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_rea_6b466pvgdhuy5tdczav5b2uyn4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/vercel/path0/frontend/packages/component/src/client-providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/vercel/path0/frontend/packages/component/src/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DataDogAnalysis"] */ "/vercel/path0/frontend/src/analytics/DataDogAnalysis.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["UserSideEffect"] */ "/vercel/path0/frontend/src/components/user-side-effect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactSection"] */ "/vercel/path0/frontend/src/layouts/sidebar-layout/navigation/contact-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayoutProvider"] */ "/vercel/path0/frontend/src/layouts/sidebar-layout/shared.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginProvider","providerMap","DEFAULT_LOGIN_PROVIDER","GOOGLE"] */ "/vercel/path0/frontend/src/lib/firebase/auth.ts");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentProjectIdProviderInternal"] */ "/vercel/path0/frontend/src/store/current-project-id/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/frontend/src/store/user/client.tsx");
