"use client";

import type { PropsWithChildren, ReactNode } from "react";
import { createContext, use } from "react";
type BaseLayoutContext = {
  serverNavigationItems: ReactNode;
  serverHeaderItems: ReactNode;
};
const BaseLayoutContext = createContext<BaseLayoutContext>(null!);
type BaseLayoutProviderProps = PropsWithChildren<{
  value: BaseLayoutContext;
}>;
export const BaseLayoutProvider = (props: BaseLayoutProviderProps) => {
  return <BaseLayoutContext.Provider {...props} data-sentry-element="unknown" data-sentry-component="BaseLayoutProvider" data-sentry-source-file="shared.tsx" />;
};
export const useBaseLayout = () => use(BaseLayoutContext);