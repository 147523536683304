import { refreshRawFiles, useUploadFiles } from "@/hooks/file";
import {
  PROJECT_KEY,
  refreshProjectById,
  refreshProjects,
} from "@/hooks/project";
import type { EmbeddingConfigType } from "@/lib/embeddings";
import { POLLING_INTERVAL } from "@/lib/utils";
import type {
  AdvancedModeTransformConfig,
  AutoTransformConfig,
  DataSinkCreate,
  DataSourceCreate,
  LlamaParseParameters,
  Pipeline,
  PipelineDeployment,
  PresetRetrievalParams,
  SupportedLlmModelNames,
} from "@llamaindex/cloud/api";
import {
  addDataSourcesToPipelineApiV1PipelinesPipelineIdDataSourcesPut,
  createDataSourceApiV1DataSourcesPost,
  createPipelineApiV1PipelinesPost,
  deletePipelineApiV1PipelinesPipelineIdDelete,
  deletePipelineFileApiV1PipelinesPipelineIdFilesFileIdDelete,
  getPipelineApiV1PipelinesPipelineIdGet,
  getPipelineDataSourceStatusApiV1PipelinesPipelineIdDataSourcesDataSourceIdStatusGet,
  getPipelineStatusApiV1PipelinesPipelineIdStatusGet,
  type GetPipelineStatusApiV1PipelinesPipelineIdStatusGetResponse,
  getPlaygroundSessionApiV1PipelinesPipelineIdPlaygroundSessionGet,
  importPipelineMetadataApiV1PipelinesPipelineIdMetadataPut,
  listPipelineJobsApiV1PipelinesPipelineIdJobsGet,
  searchPipelinesApiV1PipelinesGet,
  syncPipelineApiV1PipelinesPipelineIdSyncPost,
  updateExistingPipelineApiV1PipelinesPipelineIdPut,
} from "@llamaindex/cloud/api";
import type { QueryClient } from "@tanstack/react-query";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";

const PIPELINE_QUERY_KEY = "pipeline";
const PIPELINE_DEPLOYMENT_KEY = "pipelineDeployment";

export async function waitForPipelineExecutionFinish(
  queryClient: QueryClient,
  pipelineId: string,
) {
  const pipeline: GetPipelineStatusApiV1PipelinesPipelineIdStatusGetResponse =
    await queryClient.fetchQuery({
      queryKey: [...getPipelineKey(pipelineId), "status"],
    });
  if (pipeline.status === "IN_PROGRESS") {
    await queryClient.invalidateQueries({
      queryKey: [...getPipelineKey(pipelineId), "status"],
    });
    await new Promise((resolve) => setTimeout(resolve, POLLING_INTERVAL));
    return waitForPipelineExecutionFinish(queryClient, pipelineId);
  }
}

function useLatestPipelineExecution(pipelineId: string) {
  const { data: executions } = useDeployPipelineExecutions(pipelineId);
  return useMemo(
    () =>
      executions.reduce((acc, execution) => {
        if (acc === null) {
          return execution;
        } else {
          if (
            execution.created_at &&
            acc.created_at &&
            execution.created_at > acc.created_at
          ) {
            return execution;
          } else {
            return acc;
          }
        }
      }),
    [executions],
  );
}

const getPipelineKey = (pipelineId: string) => [PIPELINE_QUERY_KEY, pipelineId];

export async function refreshPipelineById(
  queryClient: QueryClient,
  pipelineId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: getPipelineKey(pipelineId),
  });
}

function usePipeline({ pipelineId }: { pipelineId: string }) {
  return useSuspenseQuery({
    queryKey: [...getPipelineKey(pipelineId)],
    queryFn: async () => {
      const { data } = await getPipelineApiV1PipelinesPipelineIdGet({
        path: {
          pipeline_id: pipelineId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

function usePipelineStatus(pipelineId: string) {
  return useSuspenseQuery({
    queryKey: [...getPipelineKey(pipelineId), "status"],
    queryFn: async () => {
      const { data } = await getPipelineStatusApiV1PipelinesPipelineIdStatusGet(
        {
          path: {
            pipeline_id: pipelineId,
          },
          throwOnError: true,
        },
      );

      return data.status;
    },
    refetchInterval: (query) => {
      if (query.state.data === "IN_PROGRESS") {
        return POLLING_INTERVAL;
      }
      return false;
    },
  });
}

function usePipelineDataSourceStatus(pipelineId: string, dataSourceId: string) {
  return useSuspenseQuery({
    queryKey: [PIPELINE_QUERY_KEY, pipelineId, "data_source", dataSourceId],
    queryFn: async () => {
      const { data } =
        await getPipelineDataSourceStatusApiV1PipelinesPipelineIdDataSourcesDataSourceIdStatusGet(
          {
            throwOnError: true,
            path: {
              pipeline_id: pipelineId,
              data_source_id: dataSourceId,
            },
          },
        );

      return data;
    },
    refetchInterval: (query) => {
      if (query.state.data?.status === "IN_PROGRESS") {
        return POLLING_INTERVAL;
      }
      return 5000; // to account for delays between pressing "sync" and the data source job starting
    },
  });
}

export function usePipelines(projectName: string) {
  return useSuspenseQuery({
    queryKey: [PROJECT_KEY, projectName, "pipelines"],
    queryFn: async () => {
      const { data } = await searchPipelinesApiV1PipelinesGet({
        query: { project_name: projectName },
        throwOnError: true,
      });
      return data;
    },
  });
}

export function usePipelinesByProjectId(projectId: string) {
  return useSuspenseQuery({
    queryKey: [PROJECT_KEY, projectId, "pipelines"],
    queryFn: async () => {
      const { data } = await searchPipelinesApiV1PipelinesGet({
        query: {
          project_id: projectId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

function useUpdatePipelineEvalParameters(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      llmModel,
      qaPromptTmpl,
    }: {
      llmModel: SupportedLlmModelNames;
      qaPromptTmpl: string;
    }) => {
      const { data } = await updateExistingPipelineApiV1PipelinesPipelineIdPut({
        path: {
          pipeline_id: pipelineId,
        },
        body: {
          eval_parameters: {
            llm_model: llmModel,
            qa_prompt_tmpl: qaPromptTmpl,
          },
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshPipelineById(queryClient, pipelineId);
    },
  });
}

function useUpdatePipelineName(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const { data } = await updateExistingPipelineApiV1PipelinesPipelineIdPut({
        path: {
          pipeline_id: pipelineId,
        },
        body: { name },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async (data) => {
      await refreshProjectById(queryClient, data.project_id);
    },
  });
}

function useRemovePipelineFile(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileId }: { fileId: string }) => {
      await deletePipelineFileApiV1PipelinesPipelineIdFilesFileIdDelete({
        path: {
          pipeline_id: pipelineId,
          file_id: fileId,
        },
        throwOnError: true,
      });
    },
    onSettled: async () => {
      await refreshPipelineById(queryClient, pipelineId);
      await refreshRawFiles(queryClient, pipelineId);
    },
  });
}

export const UPLOAD_BATCH_LIMIT =
  Number(process.env.NEXT_PUBLIC_UPLOAD_FILE_BATCH_SIZE) || 10;

function getDeployPipelineLastExecution(executions: PipelineDeployment[]) {
  if (executions.length === 0) {
    return null;
  }
  return executions.reduce((acc, cur) => {
    if (acc === null) {
      return cur;
    }
    if (!acc.created_at || !cur.created_at) {
      return acc;
    }
    return acc.created_at > cur.created_at ? acc : cur;
  });
}

function useDeployPipelineExecutions(pipelineId: string) {
  return useSuspenseQuery({
    queryKey: [PIPELINE_DEPLOYMENT_KEY, pipelineId, "executions"],
    queryFn: async () => {
      const { data } = await listPipelineJobsApiV1PipelinesPipelineIdJobsGet({
        path: {
          pipeline_id: pipelineId,
        },
        throwOnError: true,
      });
      return data;
    },
    refetchInterval: (query) => {
      if (query.state.data?.some((e) => e.status === "IN_PROGRESS")) {
        return POLLING_INTERVAL;
      }
      return false;
    },
  });
}

export async function refreshDeployPipelineExecutions(
  queryClient: QueryClient,
  pipelineId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: [PIPELINE_DEPLOYMENT_KEY, pipelineId, "executions"],
  });
}

function useDeployPipelineLastExecution(pipelineId: string) {
  const { data: executions } = useDeployPipelineExecutions(pipelineId);
  return useMemo(
    () => getDeployPipelineLastExecution(executions),
    [executions],
  );
}

function useUpdatePipelineRetrievalParams(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      parameters,
    }: {
      parameters: PresetRetrievalParams;
    }) => {
      const { data } = await updateExistingPipelineApiV1PipelinesPipelineIdPut({
        path: {
          pipeline_id: pipelineId,
        },
        body: { preset_retrieval_parameters: parameters },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshPipelineById(queryClient, pipelineId);
    },
  });
}

function useDeletePipeline(projectId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ pipelineId }: { pipelineId: string }) => {
      console.log("Deleting pipeline with id: ", pipelineId);
      return deletePipelineApiV1PipelinesPipelineIdDelete({
        path: {
          pipeline_id: pipelineId,
        },
        throwOnError: true,
      });
    },
    onSuccess: async (_, { pipelineId }) => {
      await refreshProjects(queryClient);
      await refreshProjectById(queryClient, projectId);
      // this pipeline is deleted,
      //  so every query related to this pipeline should be 404
      //  expected behavior is to jump to list page
      //  before fetching this pipeline
      await refreshPipelineById(queryClient, pipelineId);
    },
  });
}

/**
 * Delete files from pipeline by file ids
 */
function useDeletePipelineFiles(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fileIds }: { fileIds: string[] }) => {
      await Promise.all(
        fileIds.map((fileId) =>
          deletePipelineFileApiV1PipelinesPipelineIdFilesFileIdDelete({
            path: {
              pipeline_id: pipelineId,
              file_id: fileId,
            },
          }),
        ),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["files", pipelineId],
      });
    },
  });
}

function useImportMetadataFile(pipelineId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ file }: { file: File }) => {
      await importPipelineMetadataApiV1PipelinesPipelineIdMetadataPut({
        path: {
          pipeline_id: pipelineId,
        },
        body: {
          upload_file: file,
        },
      });
    },
    onSettled: async () => {
      await refreshPipelineById(queryClient, pipelineId);
      await refreshRawFiles(queryClient, pipelineId);
    },
  });
}

function usePlaygroundSession({ pipelineId }: { pipelineId: string }) {
  // This is for the API query only. For most cases you will want to use the `usePlayground` hook.
  return useSuspenseQuery({
    queryKey: [PIPELINE_QUERY_KEY, pipelineId, "playground_session"],
    queryFn: async () => {
      const { data } =
        await getPlaygroundSessionApiV1PipelinesPipelineIdPlaygroundSessionGet({
          path: {
            pipeline_id: pipelineId,
          },
          throwOnError: true,
        });
      return data;
    },
  });
}

export type CreateNewIndexParams = {
  name: string;
  rawFiles: File[];
  llamaParseParameters: LlamaParseParameters | null;
  dataSource: DataSourceCreate | null;
  dataSink: DataSinkCreate | null;
  transformConfig: AutoTransformConfig | AdvancedModeTransformConfig;
  syncInterval: number | null;
  embedding?: EmbeddingConfigType;
  embeddingExtraParams?: Record<string, unknown>;
  embeddingModelConfigId?: string;
  dataSinkId?: string;
  dataSourceId?: string;
};

function useUpdatePipelineParams(pipelineId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateNewIndexParams) => {
      const parseMode = data.transformConfig;
      await updateExistingPipelineApiV1PipelinesPipelineIdPut({
        path: {
          pipeline_id: pipelineId,
        },
        body: {
          name: data.name,
          transform_config: parseMode,
          embedding_model_config_id: data.embeddingModelConfigId,
          embedding_config: data.embeddingModelConfigId
            ? null
            : ({
                type: data.embedding,
                component: data.embeddingExtraParams,
              } as Pipeline["embedding_config"]),
          llama_parse_parameters: {
            ...data.llamaParseParameters,
          },
        },
      });
      await syncPipelineApiV1PipelinesPipelineIdSyncPost({
        path: { pipeline_id: pipelineId },
        throwOnError: true,
      });
    },
    onSettled: async () => {
      await refreshPipelineById(queryClient, pipelineId);
    },
  });
}

export enum CreateIndexStatus {
  Idle = "idle",
  CreatingPipeline = "creating_pipeline",
  UploadingFiles = "uploading_files",
  UpdatingPipeline = "updating_pipeline",
}

function useCreateNewIndex(projectId: string) {
  const queryClient = useQueryClient();

  const [status, setStatus] = useState<CreateIndexStatus>(
    CreateIndexStatus.Idle,
  );

  const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);
  const [totalFilesCount, setTotalFilesCount] = useState<number>(0);

  const { mutateAsync: fileUpload } = useUploadFiles(projectId);
  const allowedIndexes = undefined;
  const usedIndexes = undefined;

  const createIndexMutation = useMutation({
    mutationFn: async ({
      name,
      rawFiles,
      embedding,
      embeddingExtraParams,
      dataSource,
      dataSink,
      transformConfig,
      llamaParseParameters,
      syncInterval,
      embeddingModelConfigId,
      dataSinkId,
      dataSourceId,
    }: CreateNewIndexParams) => {
      if (
        allowedIndexes !== undefined &&
        usedIndexes !== undefined &&
        usedIndexes >= allowedIndexes
      ) {
        setStatus(CreateIndexStatus.Idle);
        throw new Error(
          "You have reached the maximum number of indexes allowed for your plan.",
        );
      }

      if (!dataSource && !dataSourceId && !rawFiles.length) {
        throw new Error(
          "Index must contain a data source. Please specify either a data source or data source ID or upload files.",
        );
      }

      setStatus(CreateIndexStatus.Idle);
      setStatus(CreateIndexStatus.CreatingPipeline);

      const { data: pipeline } = await createPipelineApiV1PipelinesPost({
        throwOnError: true,
        query: {
          project_id: projectId,
        },
        body: {
          name,
          pipeline_type: "MANAGED",
          data_sink: dataSink ?? undefined,
          transform_config: transformConfig,
          embedding_model_config_id: embeddingModelConfigId,
          data_sink_id: dataSinkId,
          embedding_config: embeddingModelConfigId
            ? null
            : ({
                type: embedding,
                component: embeddingExtraParams,
                // fixme: type here
              } as Required<Pipeline>["embedding_config"]),
          llama_parse_parameters: {
            ...llamaParseParameters,
          },
        },
      });

      let pipelineDataSourceId = dataSourceId;

      if (dataSource) {
        const { data: result } = await createDataSourceApiV1DataSourcesPost({
          body: dataSource,
          query: {
            project_id: projectId,
          },
          throwOnError: true,
        });

        pipelineDataSourceId = result.id;
      }

      if (pipelineDataSourceId) {
        await addDataSourcesToPipelineApiV1PipelinesPipelineIdDataSourcesPut({
          path: {
            pipeline_id: pipeline.id,
          },
          body: [
            {
              data_source_id: pipelineDataSourceId,
              sync_interval: syncInterval,
            },
          ],
        });
      }

      setTotalFilesCount(rawFiles.length);
      if (rawFiles.length > 0) {
        setStatus(CreateIndexStatus.UploadingFiles);
        await fileUpload({
          pipelineId: pipeline.id,
          files: rawFiles,
        });
      }
      return pipeline;
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
      setUploadedFilesCount(0);
      setStatus(CreateIndexStatus.Idle);
    },
    onError: async () => {
      await refreshProjects(queryClient);
      setUploadedFilesCount(0);
      setStatus(CreateIndexStatus.Idle);
    },
  });

  return {
    createIndex: createIndexMutation,
    createIndexStatus: status,
    uploadedFilesCount,
    totalFilesCount,
  };
}

function useByocHasManagedQdrant() {
  return useSuspenseQuery({
    queryKey: ["byocHasManagedQdrant"],
    queryFn: async () => {
      const res = await fetch("/api/deployment");
      const data = await res.json();
      if (data?.byocHasManagedQdrant) {
        return data?.byocHasManagedQdrant === "true";
      }
      return false;
    },
  });
}

export {
  useByocHasManagedQdrant,
  useCreateNewIndex,
  useDeletePipeline,
  useDeletePipelineFiles,
  useDeployPipelineExecutions,
  useDeployPipelineLastExecution,
  useImportMetadataFile,
  useLatestPipelineExecution,
  usePipeline,
  usePipelineDataSourceStatus,
  usePipelineStatus,
  usePlaygroundSession,
  useRemovePipelineFile,
  useUpdatePipelineEvalParameters,
  useUpdatePipelineName,
  useUpdatePipelineParams,
  useUpdatePipelineRetrievalParams,
};
