"use client";

import * as React from "react";
import { cn } from "@/lib/utils";
import { Icons } from "./icons";
export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;
const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  className,
  type,
  ...props
}, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const inputType = type === "password" && showPassword ? "text" : type;
  return <div className="relative w-full">
        <input type={inputType} className={cn("flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50", className, type === "password" && "pr-10")} ref={ref} {...props} />
        {type === "password" && <button type="button" className="absolute right-3 top-1/2 -translate-y-1/2" onClick={togglePasswordVisibility}>
            {showPassword ? <Icons.eye className="size-4 text-gray-500" /> : <Icons.eyeOff className="size-4 text-gray-500" />}
          </button>}
      </div>;
});
Input.displayName = "Input";
export { Input };