"use client";

import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./tooltip";
interface ToolTipperProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  delayDuration?: number;
  side?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
}
export function ToolTipper({
  content,
  children,
  delayDuration,
  side,
  disabled
}: ToolTipperProps) {
  return <Tooltip delayDuration={delayDuration} data-sentry-element="Tooltip" data-sentry-component="ToolTipper" data-sentry-source-file="tooltipper.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="tooltipper.tsx">{children}</TooltipTrigger>
      <TooltipContent side={side} hidden={disabled} data-sentry-element="TooltipContent" data-sentry-source-file="tooltipper.tsx">
        <p>{content}</p>
      </TooltipContent>
    </Tooltip>;
}
export default ToolTipper;