"use client";

import { useProjectById } from "@/hooks/project";
import type { ReactNode } from "react";
import { createContext, use } from "react";
const CurrentProjectIdContext = createContext<string | null>(null);
export const useCurrentProjectId = () => {
  const projectId = use(CurrentProjectIdContext);
  if (!projectId) {
    throw new Error("No current project found");
  }
  return projectId;
};
export const useCurrentOrganizationId = (): string => {
  const projectId = useCurrentProjectId();
  const {
    data
  } = useProjectById({
    projectId
  });
  return data.organization_id;
};
export const CurrentProjectIdProviderInternal = ({
  children,
  projectId
}: {
  children: ReactNode;
  projectId: string;
}) => {
  return <CurrentProjectIdContext.Provider value={projectId} data-sentry-element="unknown" data-sentry-component="CurrentProjectIdProviderInternal" data-sentry-source-file="client.tsx">
      {children}
    </CurrentProjectIdContext.Provider>;
};