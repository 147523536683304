import { Crisp } from 'crisp-sdk-web'
import type { User } from '@/store/user/client'

const CRISP_ENV = process?.env?.NEXT_PUBLIC_CRISP_APP_ID;

export const initializeCrisp = (user: User) => {
  if (!CRISP_ENV) return;

  Crisp.configure(CRISP_ENV, {
    autoload: false,
  });

  Crisp.setHideOnMobile(true);

  if (user.email) {
    Crisp.user.setEmail(user.email);
  }

  if (user.displayName) {
    Crisp.user.setNickname(user.displayName);
  }

  if (user.photoURL) {
    Crisp.user.setAvatar(user.photoURL);
  }

  Crisp.load();
};
