import { useNewOrganization } from "@/hooks/organization";
import { Button } from "@llamaindex/component/ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@llamaindex/component/ui/dialog";
import { Input } from "@llamaindex/component/ui/input";
import { toast } from "@llamaindex/component/ui/use-toast";
import { createContextState } from "foxact/context-state";
type CreateOrganizationState = {
  open: true;
} | {
  open: false;
};
export const [CreateOrganizationStateProvider, useCreateOrganizationState, useSetCreateOrganizationState] = createContextState<CreateOrganizationState>({
  open: false
});
export const CreateOrganizationDialog = () => {
  const state = useCreateOrganizationState();
  const setState = useSetCreateOrganizationState();
  const {
    mutateAsync
  } = useNewOrganization();
  return <Dialog open={state.open} onOpenChange={open => setState({
    open
  })} data-sentry-element="Dialog" data-sentry-component="CreateOrganizationDialog" data-sentry-source-file="create-organization.tsx">
      <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="create-organization.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="create-organization.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="create-organization.tsx">Create Organization</DialogTitle>
          <DialogDescription className="sr-only" data-sentry-element="DialogDescription" data-sentry-source-file="create-organization.tsx">
            Organizations are a way to group projects together. They can be used
            to manage access control and billing.
          </DialogDescription>
        </DialogHeader>
        <form className="contents" action={async form => {
        const organizationName = form.get("organization-name");
        if (typeof organizationName === "string" && organizationName) {
          toast.promise(mutateAsync({
            name: organizationName
          }), {
            loading: "Creating organization...",
            success: "Organization created",
            error: "Organization could not be created"
          });
        }
      }}>
          <Input name="organization-name" placeholder="Organization Name" data-sentry-element="Input" data-sentry-source-file="create-organization.tsx" />
          <DialogFooter data-sentry-element="DialogFooter" data-sentry-source-file="create-organization.tsx">
            <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="create-organization.tsx">
              <Button type="submit" data-sentry-element="Button" data-sentry-source-file="create-organization.tsx">Create</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>;
};