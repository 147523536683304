import { AppSidebar } from "@/layouts/sidebar-layout/sidebar";
import { cn } from "@/lib/utils";
import { MobileSidebarTrigger } from "@llamaindex/component/ui/sidebar";
import type { ReactNode } from "react";
import { Suspense } from "react";
export type BaseLayoutClientProps = {
  className?: string;
  children: ReactNode;
  navigation?: ReactNode;
};
export function SidebarLayout(props: BaseLayoutClientProps) {
  return <>
      <AppSidebar data-sentry-element="AppSidebar" data-sentry-source-file="index.tsx" />
      <main className="relative flex h-screen max-h-screen min-h-screen w-full flex-col overflow-hidden" data-testid="root-layout">
        <Suspense data-sentry-element="Suspense" data-sentry-source-file="index.tsx">
          <MobileSidebarTrigger data-sentry-element="MobileSidebarTrigger" data-sentry-source-file="index.tsx" />
        </Suspense>
        <div className={cn("flex h-full grow flex-col overflow-auto bg-sidebar", props.className)}>
          <Suspense fallback={<div className="flex h-full flex-col items-center justify-center">
                <div className="size-6 animate-spin rounded-full border-4 border-dashed border-blue-500" />
              </div>} data-sentry-element="Suspense" data-sentry-source-file="index.tsx">
            {props.children}
          </Suspense>
        </div>
      </main>
    </>;
}