"use client";

import { MainSection } from "@/layouts/sidebar-layout/navigation/main-section";
import { ProjectSwitcher } from "@/layouts/sidebar-layout/project-switcher";
import { useBaseLayout } from "@/layouts/sidebar-layout/shared";
import { Alert, AlertDescription, AlertTitle } from "@llamaindex/component/ui/alert";
import { Button } from "@llamaindex/component/ui/button";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarMenuSkeleton, SidebarSeparator, SidebarTrigger } from "@llamaindex/component/ui/sidebar";
import { AlertCircle } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { Suspense } from "react";
import { ErrorBoundary, type FallbackProps } from "react-error-boundary";
const NavSkeleton = () => <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-component="NavSkeleton" data-sentry-source-file="sidebar.tsx">
    {Array.from({
    length: 5
  }).map((_, index) => <SidebarMenuItem key={index}>
        <SidebarMenuSkeleton />
      </SidebarMenuItem>)}
  </SidebarMenu>;
const NavError = ({
  error,
  resetErrorBoundary
}: FallbackProps) => {
  return <div className="flex size-full flex-col items-center justify-center p-4" data-sentry-component="NavError" data-sentry-source-file="sidebar.tsx">
      <Alert variant="destructive" className="w-full" data-sentry-element="Alert" data-sentry-source-file="sidebar.tsx">
        <AlertCircle className="size-4" data-sentry-element="AlertCircle" data-sentry-source-file="sidebar.tsx" />
        <AlertTitle data-sentry-element="AlertTitle" data-sentry-source-file="sidebar.tsx">Navigation Error</AlertTitle>
        <AlertDescription className="mt-2 flex flex-col gap-2" data-sentry-element="AlertDescription" data-sentry-source-file="sidebar.tsx">
          <p className="text-sm">Failed to load navigation items.</p>
          {process.env.NODE_ENV === "development" && <p className="text-xs opacity-80">{error.message}</p>}
          <Button onClick={resetErrorBoundary} variant="outline" size="sm" className="mt-2" data-sentry-element="Button" data-sentry-source-file="sidebar.tsx">
            Try again
          </Button>
        </AlertDescription>
      </Alert>
    </div>;
};
export const AppSidebar = () => {
  const {
    serverNavigationItems
  } = useBaseLayout();
  return <Sidebar collapsible="icon" data-sentry-element="Sidebar" data-sentry-component="AppSidebar" data-sentry-source-file="sidebar.tsx">
      <ErrorBoundary fallbackRender={NavError} data-sentry-element="ErrorBoundary" data-sentry-source-file="sidebar.tsx">
        <Suspense data-sentry-element="Suspense" data-sentry-source-file="sidebar.tsx">
          <SidebarTrigger data-sentry-element="SidebarTrigger" data-sentry-source-file="sidebar.tsx" />
        </Suspense>
        <SidebarHeader data-sentry-element="SidebarHeader" data-sentry-source-file="sidebar.tsx">
          <Suspense fallback={<NavSkeleton />} data-sentry-element="Suspense" data-sentry-source-file="sidebar.tsx">
            <Link href="/" className="p-4 group-data-[collapsible=icon]:p-0" data-sentry-element="Link" data-sentry-source-file="sidebar.tsx">
              <Image src="/assets/logo.svg" alt="LlamaIndex" className="h-8 w-auto cursor-pointer group-data-[collapsible=icon]:hidden" height={64} width={120} data-sentry-element="Image" data-sentry-source-file="sidebar.tsx" />
              <Image src="/assets/rounded_corners_logo.png" alt="LlamaIndex" className="hidden h-8 w-auto cursor-pointer group-data-[collapsible=icon]:block" height={32} width={32} data-sentry-element="Image" data-sentry-source-file="sidebar.tsx" />
            </Link>
            <SidebarSeparator data-sentry-element="SidebarSeparator" data-sentry-source-file="sidebar.tsx" />
            <ProjectSwitcher data-sentry-element="ProjectSwitcher" data-sentry-source-file="sidebar.tsx" />
            <SidebarSeparator data-sentry-element="SidebarSeparator" data-sentry-source-file="sidebar.tsx" />
          </Suspense>
        </SidebarHeader>
      </ErrorBoundary>
      <SidebarContent className="text-sm" data-sentry-element="SidebarContent" data-sentry-source-file="sidebar.tsx">
        <ErrorBoundary fallbackRender={NavError} data-sentry-element="ErrorBoundary" data-sentry-source-file="sidebar.tsx">
          <Suspense fallback={<NavSkeleton />} data-sentry-element="Suspense" data-sentry-source-file="sidebar.tsx">
            <MainSection data-sentry-element="MainSection" data-sentry-source-file="sidebar.tsx" />
          </Suspense>
        </ErrorBoundary>
      </SidebarContent>
      <SidebarFooter className="p-0" data-sentry-element="SidebarFooter" data-sentry-source-file="sidebar.tsx">
        <ErrorBoundary fallbackRender={NavError} data-sentry-element="ErrorBoundary" data-sentry-source-file="sidebar.tsx">
          <Suspense fallback={<SidebarMenu>
                {Array.from({
            length: 5
          }).map((_, index) => <SidebarMenuItem key={index}>
                    <SidebarMenuSkeleton showIcon />
                  </SidebarMenuItem>)}
              </SidebarMenu>} data-sentry-element="Suspense" data-sentry-source-file="sidebar.tsx">
            {serverNavigationItems}
          </Suspense>
        </ErrorBoundary>
      </SidebarFooter>
    </Sidebar>;
};