"use client";

import { datadogRum } from "@datadog/browser-rum";
import type React from "react";
import { useEffect } from "react";
let dataDogInitialized = false;
export const DataDogAnalysis: React.FC = function DataDogAnalyais() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === undefined) {
      console.log("Skip tracking DataDog RUM");
      return;
    }
    if (dataDogInitialized) {
      return;
    }
    datadogRum.init({
      applicationId: "b31063c8-9acc-4cce-8e82-cef4133981fb",
      clientToken: "pub27287483182ccbfd215b0ee9be38746d",
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "us5.datadoghq.com",
      service: "llamacloud",
      env: process.env.NEXT_PUBLIC_VERCEL_ENV,
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input"
    });
    console.log("DataDog RUM initialized");
    dataDogInitialized = true;
  }, []);
  return null;
};