"use client";

import { SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from "@llamaindex/component/ui/sidebar";
import { ChartNoAxesColumnIncreasing, Mail } from "lucide-react";
import Link from "next/link";
import React from "react";
export const ContactSection: React.FC = () => {
  return <SidebarGroup className="group-data-[collapsible=icon]:hidden" data-sentry-element="SidebarGroup" data-sentry-component="ContactSection" data-sentry-source-file="contact-section.tsx">
      <SidebarGroupLabel data-sentry-element="SidebarGroupLabel" data-sentry-source-file="contact-section.tsx">Contacts</SidebarGroupLabel>
      <SidebarGroupContent data-sentry-element="SidebarGroupContent" data-sentry-source-file="contact-section.tsx">
        <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="contact-section.tsx">
          <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="contact-section.tsx">
            <SidebarMenuButton size="sm" asChild data-sentry-element="SidebarMenuButton" data-sentry-source-file="contact-section.tsx">
              <a href="mailto:support@runllama.ai" className="flex cursor-pointer items-center rounded py-1">
                <Mail className="size-4" data-sentry-element="Mail" data-sentry-source-file="contact-section.tsx" />
                <span>Email Us</span>
              </a>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="contact-section.tsx">
            <SidebarMenuButton size="sm" asChild data-sentry-element="SidebarMenuButton" data-sentry-source-file="contact-section.tsx">
              <Link href="https://llamaindex.statuspage.io" className="flex items-center rounded py-1" data-sentry-element="Link" data-sentry-source-file="contact-section.tsx">
                <ChartNoAxesColumnIncreasing className="size-4" data-sentry-element="ChartNoAxesColumnIncreasing" data-sentry-source-file="contact-section.tsx" />
                <span>Status</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>;
};