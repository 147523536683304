"use client";
import {
  createProjectApiV1ProjectsPost,
  deleteProjectApiV1ProjectsProjectIdDelete,
  getProjectApiV1ProjectsProjectIdGet,
  listProjectsApiV1ProjectsGet,
  updateExistingProjectApiV1ProjectsProjectIdPut,
} from "@llamaindex/cloud/api";
import type { QueryClient } from "@tanstack/react-query";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";

export const PROJECT_KEY = "projectInfo";
export const PROJECTS_KEY = "projects";

export function useProjectById({ projectId }: { projectId: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECT_KEY, projectId],
    queryFn: async () => {
      const { data } = await getProjectApiV1ProjectsProjectIdGet({
        path: {
          project_id: projectId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

export async function refreshProjectById(
  queryClient: QueryClient,
  projectId: string,
) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECT_KEY, projectId],
  });
}

export async function refreshProjects(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    queryKey: [PROJECTS_KEY],
  });
}

export function useProjects(params?: { organizationId?: string }) {
  return useSuspenseQuery({
    queryKey: [PROJECTS_KEY, params],
    queryFn: async () => {
      const { data } = await listProjectsApiV1ProjectsGet({
        query: {
          organization_id: params?.organizationId,
        },
        throwOnError: true,
      });
      return data;
    },
  });
}

export function useNewProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      name,
      organizationId,
    }: {
      name: string;
      organizationId?: string;
    }) => {
      const { data } = await createProjectApiV1ProjectsPost({
        body: {
          name,
        },
        query: {
          organization_id: organizationId,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
    },
  });
}

export function useUpdateProject() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      projectId,
      name,
    }: {
      projectId: string;
      name: string;
    }) => {
      const { data } = await updateExistingProjectApiV1ProjectsProjectIdPut({
        path: {
          project_id: projectId,
        },
        body: {
          name,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
    },
  });
}

export function useDeleteProject(params?: { organizationId?: string }) {
  const queryClient = useQueryClient();
  const { data: projects } = useProjects(params);
  return useMutation({
    mutationFn: async ({ projectId }: { projectId: string }) => {
      if (projects.length === 1) {
        throw new Error("Cannot delete the last project in the organization");
      }
      const { data } = await deleteProjectApiV1ProjectsProjectIdDelete({
        path: {
          project_id: projectId,
        },
        throwOnError: true,
      });
      return data;
    },
    onSuccess: async () => {
      await refreshProjects(queryClient);
    },
  });
}
