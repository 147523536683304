"use client";
import { firebaseConfig, runtimeEnv } from '@/lib/runtime'
import { initializeApp } from "firebase/app";
import { getAuth, getRedirectResult, type UserCredential } from 'firebase/auth'

export const firebaseApp =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : initializeApp(firebaseConfig);

export const googleAuth =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : getAuth(firebaseApp);

export const redirectResultPromise: Promise<UserCredential | null> =
  typeof window !== "undefined" && googleAuth
    ? getRedirectResult(googleAuth)
    : Promise.resolve(null);

export let resolvedPromise = false;
export const resolvedPromiseFn = () => {
  resolvedPromise = true;
};