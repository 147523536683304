import { SidebarLayout } from "@/layouts/sidebar-layout";
import { SimpleLayout } from "@/layouts/simple-layout";
import { Loader2 } from "lucide-react";
export function SidebarLayoutSkeleton() {
  return <SidebarLayout data-sentry-element="SidebarLayout" data-sentry-component="SidebarLayoutSkeleton" data-sentry-source-file="loading.tsx">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="size-6 animate-spin rounded-full border-4 border-dashed border-blue-500" />
      </div>
    </SidebarLayout>;
}
export function SimpleLayoutSkeleton() {
  return <SimpleLayout data-sentry-element="SimpleLayout" data-sentry-component="SimpleLayoutSkeleton" data-sentry-source-file="loading.tsx">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="size-6 animate-spin rounded-full border-4 border-dashed border-blue-500" />
      </div>
    </SimpleLayout>;
}
type LoadingStatusProps = {
  message?: string;
};
const LoadingStatus = (props: LoadingStatusProps) => <div className="flex flex-row items-center space-x-2" data-sentry-component="LoadingStatus" data-sentry-source-file="loading.tsx">
    <Loader2 className="size-5 animate-spin stroke-slate-500" data-sentry-element="Loader2" data-sentry-source-file="loading.tsx" />
    <div className="flex flex-col text-sm text-slate-500">
      <div>{props.message ?? "Loading"}</div>
    </div>
  </div>;
export { LoadingStatus };